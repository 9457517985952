import React from 'react';
import {withStyles, Box, Grid} from "@material-ui/core";
import clsx from "clsx";

// internal
import styles from "./styles";
import Quiz from "../../components/Quiz";

function TrainingStep(props) {
  const {
    classes, step, totalStep, training, countdown, onScroll,
    changeAnswer, answered, showAnswer, answerCorrect, setAutoShowAns
  } = props;

  const formatTimer = (timer) => {
    if (!timer) return "";
    const minute = Math.floor(timer / 60);
    let second = timer % 60;
    if (second < 10) second = '0' + second;
    return minute + ":" + second;
  }

  return (
    <Box className={classes.wrapper}>
      <Grid container direction="column" wrap="nowrap" className={classes.wrapper}>
        <Grid item>
          <Box mb={1}>
            <Grid container spacing={1} justify={step === totalStep ? "center" : "space-between"} alignItems="center" wrap="nowrap">
              <Grid item>
                <Box component="h2" m={0}>{training.title}</Box>
              </Grid>
              <Grid item>
                <Box className={classes.timer}>{formatTimer(countdown)}</Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item id="training-body" onScroll={onScroll} onClick={onScroll}
              className={clsx({
                [classes.body]: true,
                [classes.center]: step === totalStep,
              })}
        >
          <Box>
            <div dangerouslySetInnerHTML={{__html: training.content}} />
          </Box>
          <Box>
            <Quiz quizList={training.quiz_list}
                  changeAnswer={changeAnswer}
                  answered={answered}
                  showAnswer={showAnswer}
                  setAutoShowAns={setAutoShowAns}
                  disabled={(training.data && training.data.completed) || answerCorrect}
            />
          </Box>
        </Grid>
        {training && training.note && (
          <Grid item>
            <Box className={classes.note}>{training.note}</Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default withStyles(styles)(TrainingStep);