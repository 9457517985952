import driverAppApi from "./driverAppApi";

export const getTraining = (courseId, token, params) => driverAppApi.get(`/training/${courseId}`, params, {headers: {'Authorization': `Bearer ${token}`}});
export const updateTraining = (courseId, trainingId, data, token) => driverAppApi.put(`/training/${courseId}/${trainingId}`, data, {headers: {'Authorization': `Bearer ${token}`}});
export const startTraining = (courseId, token) => driverAppApi.put(`/training/${courseId}/start`, {}, {headers: {'Authorization': `Bearer ${token}`}});
export const exitTraining = (token) => driverAppApi.post(`/training/exit`, {}, {headers: {'Authorization': `Bearer ${token}`}});

export const getTemplate = (templateId, token, params) => driverAppApi.get(`/registration/contract/template/${templateId}`, params, {headers: {'Authorization': `Bearer ${token}`}});
export const getAgreement = (agreementId, token, params) => driverAppApi.get(`/registration/contract/agreement/${agreementId}`, params, {headers: {'Authorization': `Bearer ${token}`}});
export const submitAgreement = (url, templateId, token, data, params) => {
  if (url) {
    url = url.replace('{template_id}', templateId)
  }
  return driverAppApi.post(url || `/registration/contract/agreement/${templateId}`, data,
    {params, headers: {'Authorization': `Bearer ${token}`},}
  );
};
export const getAgreements = (token, params) => driverAppApi.get(`/registration/contract/agreements`, params, {headers: {'Authorization': `Bearer ${token}`}});
