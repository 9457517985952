export const CONTRACT_STATUS = {
  NOT_YET_AGREED: 'NOT YET AGREED',
  EXPIRED: 'EXPIRED',
  AGREED: 'AGREED',
  RENEW_NEEDED: 'Renew Needed',
}

export const CONTRACT_TYPE = {
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TEXT_PRIVACY_POLICY: 'Privacy Policy',
  TEXT_CONTRACT_AGREEMENT: 'Contract Agreement',
}