import React, {useEffect} from 'react';
import {date2str, exportToCsv} from '../../../utils/sampleInputFile';

export default function SimpleSampleInput() {
  const dropoff_earliest_datetime = date2str(new Date(Date.now() + 24*60*60*1000), 'MM/dd/yyyy hh:mm:00');
  const dropoff_latest_datetime   = date2str(new Date(Date.now() + 26*60*60*1000), 'MM/dd/yyyy hh:mm:00');

  useEffect(() => {exportToCsv('basic-sample.csv', [
    ['pickup_street','pickup_street2','pickup_city','pickup_state','pickup_zipcode','order_id','dropoff_street','dropoff_street2','dropoff_city','dropoff_state','dropoff_zipcode','dropoff_earliest_datetime','dropoff_latest_datetime','customer_name','customer_email','customer_phone_number','dropoff_notes', 'dimension_unit', 'height', 'width', 'length', 'weight_unit', 'weight','value','currency'],
    ['2320 Warring Street','Hall A','Berkeley','CA','94704','FRUIT-NINJA-101','1790 Jackson Street','Apartment 1',' San Francisco','CA','94109',dropoff_earliest_datetime , dropoff_latest_datetime,'Pamela Dummy','pamela@dummy.com','3108170029','Do not ring the bell','in','10','15','10','lb','1','100.1','USD'],
    ['2320 Warring Street','Hall A','Berkeley','CA','94704','FRUIT-NINJA-102','1 Federal Street','Floor 2',' San Francisco','CA','94107',dropoff_earliest_datetime , dropoff_latest_datetime,'Lorraine Dummy','lorraine@dummy.com','3108170029','Beware of dogs','in','10','15','10','lb','1','78.15','USD'],
    ['2320 Warring Street','Hall A','Berkeley','CA','94704','FRUIT-NINJA-103','1008 De Haro Street','Penthouse',' San Francisco','CA','94107',dropoff_earliest_datetime ,dropoff_latest_datetime ,'Loretta Dummy','loretta@dummy.com','3108170029','Leave it by the mailboxes','in','10','15','10','lb','1','45.01','USD'],
    ['2320 Warring Street','Hall A','Berkeley','CA','94704','FRUIT-NINJA-104','730 Florida Street','Basement 101',' San Francisco','CA','94110',dropoff_earliest_datetime ,dropoff_latest_datetime ,'Virginia Dummy','virginia@dummy.com','3108170029','Hide it in the bush ','in','10','15','10','lb','1','100.1','USD'],
    ['2320 Warring Street','Hall A','Berkeley','CA','94704','FRUIT-NINJA-105','1218 Treat Avenue','Detached Unit C',' San Francisco','CA','94110',dropoff_earliest_datetime ,dropoff_latest_datetime ,'Rashad Dummy','rashad@dummy.com','3108170029','Place on the patio table','in','10','15','10','lb','1','100.1','USD'],
    ['2320 Warring Street','Hall A','Berkeley','CA','94704','FRUIT-NINJA-106','30 Jersey Street','Room 105',' San Francisco','CA','94114',dropoff_earliest_datetime ,dropoff_latest_datetime ,'Eva Dummy','eva@dummy.com','3108170029','My house is hard to find, call me when you arrive','in','10','15','10','lb','1','100.1','USD'],
    ['2320 Warring Street','Hall A','Berkeley','CA','94704','FRUIT-NINJA-110','1066 Guerrero Street','Ceiling',' San Francisco','CA','94110',dropoff_earliest_datetime , dropoff_latest_datetime,'David Dummy','david@dummy.com','3108170029','Baby inside, leave the box quietly','in','10','15','10','lb','1','55','USD']
  ]);
  }, []);

  return <div>Downloading...</div>;
};