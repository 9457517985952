const buttonStyle = {
  borderWidth: 2,
  borderRadius: 20,
  borderStyle: 'solid',
  '&:hover': {
    borderWidth: 2,
  },
};

const styles = theme => ({
  logo: {
    color: '#3b3b3b',
    fontFamily: "AvenirNext-Medium",
    fontSize: 20,
  },
  stepText: {
    fontFamily: 'AvenirNext-DemiBold',
    color: '#887fff',
    fontSize: 10,
    letterSpacing: 1
  },
  stepAction: {
    backgroundColor: '#fff',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  trainingBody: {
    height: 'calc(100vh - 160px)',
  },
  button: buttonStyle,
  actionButton: {
    ...buttonStyle,
    '& >.MuiButton-label': {
      fontSize: 16,
    }
  },
  error: {
    color: '#f00',
    fontStyle: 'italic',
    fontFamily: "AvenirNext-DemiBold",
    fontWeight: 500,
  },
})

export default styles;