import { Box, Button, Divider, Paper, Typography } from '@material-ui/core'
import React from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './styles';
import { CONTRACT_STATUS } from '../../constants/contract';
import { buildButtonActon } from '../../utils/mobileCommunication';
import { URL_PARAMS } from '../../constants/urlParams/urlParams';

const InlineH6 = ({children, ...otherProps}) => <Typography display='inline' variant="h6" {...otherProps}> {children} </Typography>

function Detail({token, template, groupIds, includeCats, setDocumentSigned, excludeCats }) {
  const classes = useStyles();
  const history = useHistory();

  const handleView = () => {
    if(template.status === CONTRACT_STATUS.AGREED && !template.warning) {
      if (window.TSAMessageChannel) {
        const json = JSON.stringify(buildButtonActon('open-pdf', template.contract_url, template.name));
        window.TSAMessageChannel.postMessage(json);
      }
      else {
        setDocumentSigned({
          contractUrl: `${template.contract_url}#toolbar=0&navpanes=0&scrollbar=0`,
          isOpen: true,
        });  
      }
      return;
    }

    history.push(`document/${template?.id}?${URL_PARAMS.TOKEN}=${token}&${URL_PARAMS.GROUP_IDS}=${groupIds?.join(",")}&${URL_PARAMS.FROM_LIST}=${true}&${URL_PARAMS.INCLUDES}=${includeCats?.join(',')}&${URL_PARAMS.EXCLUDES}=${excludeCats?.join(',')}`);
  }

  return (
    <Paper elevation={1} classes={{root: classes.rootPaper}}>
      <Box display='flex' justifyContent='space-between' px={2} pt={2} pb={template.warning ? 0.5 : 2} alignItems={'center'}>
        <Box display='flex' alignItems='center' maxWidth={'60%'} ml={-0.5}>
          <DescriptionIcon fontSize='small'/>
          <InlineH6 classes={{root: classes.title}}>{template?.name} </InlineH6>
        </Box>
        <Box>
          <Button variant="contained" classes={{root: classes.rootButton}} onClick={handleView}>View</Button>
        </Box>
      </Box>
      <Box px={2} my={template.warning ? 0.5 : 0}>
        {template.warning && <InlineH6 classes={{root: classes.titleWarning}} color='error'>{CONTRACT_STATUS.RENEW_NEEDED}</InlineH6>}
      </Box>

      <Divider/>
      <Box px={2} display='flex' py={1} alignItems={'flex-end'}>
        <InlineH6 classes={{root: classes.titleStatus}}>Status</InlineH6>
        <Box>
          {
            [CONTRACT_STATUS.NOT_YET_AGREED, CONTRACT_STATUS.EXPIRED].includes(template.status) 
            ? <InlineH6 classes={{root: classes.valueStatus}} color='error'>{template.status}</InlineH6> 
            : <InlineH6 classes={{root: clsx({
              [classes.valueStatus]: true,
              [classes.statusSigned]: !template.warning,
              [classes.statusWarning]: template.warning,
            })}}>{template.status} - {new Date(template.signed_ts).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' })} </InlineH6>
          }
        </Box>
      </Box>
    </Paper>
  )
}

export default Detail
