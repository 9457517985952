import React, {useEffect, useState} from 'react';
import {withStyles, Box, FormControlLabel, Checkbox, Button, LinearProgress, CircularProgress, Typography} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useRecoilState } from 'recoil';

import styles from "./styles";
import * as repo from "../../stores/repositories";
import { buildMobileDialogMessage, MOBILE_DIALOG_TYPE} from "../../utils/mobileCommunication";
import AxlDialog from '../../components/AxlDialog';
import { setAgreements } from '../../atoms/agreements/selector';
import { CONTRACT_STATUS, CONTRACT_TYPE } from '../../constants/contract';
import { URL_PARAMS } from '../../constants/urlParams/urlParams';
import { filterTemplate } from '../../utils/filterTemplate';

let cloneTemplates = [];

function Documentation(props) {
  const {classes, match, contract, isFinal, groupIds, submitCallback} = props;
  const {id} = match ? match.params : {};
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState({});
  const [checked, setChecked] = useState([]);
  const [error, setError] = useState("");
  const history = useHistory();
  const [isCongratulations, setIsCongratulations] = useState(false);
  const [agreement, setAgreement] = useRecoilState(setAgreements);
  const [message, setMessage] = useState(null);
  cloneTemplates = _.cloneDeep(agreement?.templates);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get(URL_PARAMS.TOKEN);
  const redirect_to = urlParams.get('redirect_to');

  useEffect(() => {
    if (!!id && !contract) {
      setLoading(true);
      repo.getTemplate(id, token, {}).then(res => {
        if (res.ok) {
          setTemplate(res.data);
        } else {
          setTemplate({});
          setError(res.data ? res.data.message : "Error while sent request");
        }
        setLoading(false);
      })
    } else {
      setTemplate(contract);
    }
  }, []);

  useEffect(() => {
    setTemplate(contract);
  }, [contract]);

  const isAgree = () => {
    return !template.checkboxes || !template.checkboxes.length || template.checkboxes.length === checked.length;
  }

  const handleCheckbox = (e, i) => {
    const checkedSet = new Set(checked);
    if (e.target.checked) {
      checkedSet.add(i);
    } else {
      checkedSet.delete(i);
    }
    setChecked(Array.from(checkedSet));
  }

  const onSubmit = () => {
    if (isAgree()) {
      const urlIsFinal = cloneTemplates?.filter(ct => ct.status?.toUpperCase() === CONTRACT_STATUS.AGREED && !ct.warning)?.length + 1 === cloneTemplates?.length;
      const urlFromList = urlParams.get(URL_PARAMS.FROM_LIST)?.toLowerCase() === 'true';
      const urlGroupIds = urlParams.get(URL_PARAMS.GROUP_IDS)?.split(',') || [];
      const urlIncludes = urlParams.get(URL_PARAMS.INCLUDES)?.split(',') || [];
      const urlExcludes = urlParams.get(URL_PARAMS.EXCLUDES)?.split(',') || [];

      setSubmitting(true);
      setIsCongratulations(false);
      repo.submitAgreement(
        template.submit_url, template.id, token, {},
        {group_ids: urlFromList ? urlGroupIds : groupIds, is_final: urlFromList ? urlIsFinal : isFinal}
      ).then(res => {
        if (res.ok) {
          setAgreement({id: template.id, contract_url: res.data.contract_url, signed_ts: new Date(), status: CONTRACT_STATUS.AGREED, warning: false});
          if(urlFromList) {
            setChecked([]);
            setSubmitting(false);
            cloneTemplates = agreement?.templates?.map(item => {
              if(item.id === template.id) {
                return {
                  ...item,
                  contract_url: res.data.contract_url, 
                  signed_ts: new Date(), 
                  status: CONTRACT_STATUS.AGREED,
                  warning: false,
                }
              }
              return item;
            });
            const isAllSigned = !cloneTemplates?.some(ct => [CONTRACT_STATUS.NOT_YET_AGREED, CONTRACT_STATUS.EXPIRED].includes(ct.status?.toUpperCase()) || ct.warning);
            if(isAllSigned) {
              if (window.TSAMessageChannel) {
                let description = "You have completed the Technology Service Agreement renewal process. A copy of the Agreement can be found under Profile > Legal. Please press OK & you will be directed to our booking screen for available delivery opportunities.";      
                if (template.owner_type === "DSP") {
                  description = "Congratulations! You have signed the updated third party agreement. Please press OK and you continue to drive for Jitsu via your DSP.";
                }
                const json = JSON.stringify(buildMobileDialogMessage(MOBILE_DIALOG_TYPE.SUCCESS, "Congratulations!", description, '/booking'));
                window.TSAMessageChannel.postMessage(json);
              }
              else {
                history.push(`/agreements?${URL_PARAMS.TOKEN}=${token}&${URL_PARAMS.INCLUDES}=${urlParams.get(URL_PARAMS.INCLUDES)}&${URL_PARAMS.EXCLUDES}=${urlParams.get(URL_PARAMS.EXCLUDES)}`);
              }
              return;
            }
            const filterByCategory = filterTemplate(cloneTemplates, urlIncludes, urlExcludes);
            const isAllSignedByCategory = filterByCategory?.filter(at => at.status?.toUpperCase() === CONTRACT_STATUS.AGREED && !at.warning)?.length === filterByCategory?.length;
            setIsCongratulations(true);
            
            if(isAllSignedByCategory) {
              setMessage(template.category === CONTRACT_TYPE.PRIVACY_POLICY ? 'You also need to review and sign contract agreement' : 'You also need to review and sign Privacy Policy')
            }
            else {
              setMessage('');
            }
            return;
          }

          if (!isFinal) {
            setChecked([]);
            setSubmitting(false);
          } else {
            if (window.TSAMessageChannel) {
              let description = "You have completed the registration. Please press OK and you will be prompted to login momentarily to start driving for Jitsu.";
              if (redirect_to === '/booking') {
                description = "You have completed the Technology Service Agreement renewal process. A copy of the Agreement can be found under Profile > Legal > Technology Services Agreement. Please press OK & you will be directed to our booking screen for available delivery opportunities.";
                if (template.owner_type === "DSP") {
                  description = "Congratulations! You have signed the updated third party agreement. Please press OK and you continue to drive for Jitsu via your DSP.";
                }
              } else if (redirect_to === '/tsa') {
                description = "Your contract has been signed!"
              }

              const json = JSON.stringify(buildMobileDialogMessage(
                MOBILE_DIALOG_TYPE.SUCCESS, "Congratulations!",
                description, redirect_to
              ));
              window.TSAMessageChannel.postMessage(json);
            }
          }
        } else {
          if (res.status == null || res.problem === "NETWORK_ERROR" || res.status === 401 || res.status === 403) {
            // network error
          } else {
            if (window.TSAMessageChannel) {
              const json = JSON.stringify(buildMobileDialogMessage(
                MOBILE_DIALOG_TYPE.ERROR, "ERROR!",
                "Some errors happened while submitting your request. Please check your connection and try again!",
                "/back"
              ));
              window.TSAMessageChannel.postMessage(json);
            } else {
              alert("ERROR")
            }
          }

          setSubmitting(false);
        }

        if (submitCallback) submitCallback(res);
      })
    } else {
      alert("You must agree first!")
    }
  }

  if (loading) {
    return (
      <Box textAlign="center"><LinearProgress color="primary" /></Box>
    )
  }

  if (!template || !template.html) {
    return (
      <Box>Fail to load document!</Box>
    )
  }

  const handleOk = () => {
    setChecked([]);
    setMessage('')
    setIsCongratulations(false);
    history.push(`/agreements?${URL_PARAMS.TOKEN}=${token}&${URL_PARAMS.INCLUDES}=${urlParams.get(URL_PARAMS.INCLUDES)}&${URL_PARAMS.EXCLUDES}=${urlParams.get(URL_PARAMS.EXCLUDES)}`);
    return;
  }

  return (
    <Box className={classes.container}>
      <Box>
        <div dangerouslySetInnerHTML={{__html: template.html}} />
      </Box>
      <Box mt={1}>
        {template.checkboxes && template.checkboxes.map((text, i) => (
          <Box key={i}>
            <FormControlLabel
              label={<strong>{text}</strong>}
              control={<Checkbox checked={checked.includes(i)}
                                 onChange={e => handleCheckbox(e, i)}
                                 color="primary"
                                 name="agree-checked" />
              }
            />
          </Box>
        ))}
        <Box className={classes.actions}>
          <Button disabled={!isAgree() || submitting}
                  onClick={() => onSubmit(template)}
                  className={classes.agreeButton}
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="primary"
          >
            {!submitting ? (template.submit_text || "I Agree") : <CircularProgress color="primary" size={24} />}
          </Button>
        </Box>
      </Box>

      <AxlDialog title={'Congratulations'} content={<Typography align='center' className={classes.typoCongratulations}>{`Your contract has been signed! ${message}`}</Typography>} handleOk={handleOk} isOpen={isCongratulations}/>
    </Box>
  )
}

export default withStyles(styles)(Documentation);
