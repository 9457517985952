import React, {useEffect, useState} from 'react';
import {withStyles, Box, LinearProgress} from "@material-ui/core";

import styles from "./styles";
import * as repo from "../../stores/repositories";
import Documentation from "../Document";

function Agreement(props) {
  const {classes, match} = props;
  const {id} = match.params;
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState({});
  const [contract, setContract] = useState({});
  const [isFinal, setIsFinal] = useState(false);
  const [step, setStep] = useState(0);
  const [error, setError] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('tk');

  useEffect(() => {
    if (!!id) {
      setLoading(true);
      repo.getAgreement(id, token, {}).then(res => {
        if (res.ok) {
          setAgreement(res.data);
          initData(res.data);
        } else {
          setAgreement({});
          setError(res.data ? res.data.message : "Error while sent request");
        }
        setLoading(false);
      })
    }
  }, []);

  useEffect(() => {
    initData(agreement);
  }, [step]);

  const initData = (agreement) => {
    const contract = agreement.templates ? agreement.templates[step] : {};
    const isFinal = agreement.templates ? step === agreement.templates.length - 1 : true;

    setContract(contract);
    setIsFinal(isFinal);
  }

  const onSubmitCallback = (res) => {
    if (res.ok) {
      if (!isFinal) {
        setStep(step + 1);
        window.scrollTo(0, 0);
      }
    }
  }

  if (loading) {
    return (
      <Box textAlign="center"><LinearProgress color="primary" /></Box>
    )
  }

  if (!agreement || !agreement.templates || !agreement.templates.length) {
    return (
      <Box textAlign={'center'} p={2} color='red'>Fail to load document!</Box>
    )
  }

  return (
    <Box>
      <Documentation contract={contract} isFinal={isFinal} groupIds={agreement.template_ids} submitCallback={onSubmitCallback} />
    </Box>
  )
}

export default withStyles(styles)(Agreement);