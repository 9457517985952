import { makeStyles } from "@material-ui/core";
import colors from "../../themes/colors";

export const useStyles = makeStyles((theme) => ({
  rootPaper: {
    margin: 16,
    borderRadius: 16,
  },
  title: {
    marginLeft: 4,
    fontSize: '1.125rem',
    fontWeight: 400,
  },
  rootButton: {
    backgroundColor: colors.blueNightThird,
    color: colors.white,
    minWidth: 120,
    borderRadius: 24,
    padding: '4px 16px',

    "&:hover": {
      backgroundColor: colors.blueNightThird,
    }
  },
  titleWarning: {
    fontSize: '0.875rem',
    fontWeight: 400,
    fontStyle: 'italic',
  },
  titleStatus: {
    fontWeight: 400,
  },
  valueStatus: {
    marginLeft: 4,
    textTransform: 'uppercase',
    fontSize: '0.875rem',
  },
  statusSigned: {
    color: colors.green,
  },
  statusWarning: {
    color: '#f44336',
  }
}))