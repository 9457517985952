export default {
  main: '#887fff',
  whiteTwo: '#fafafa',
  white: '#fff',
  blueEyes: "#f4f3ff",
  black: '#000000',
  black35: 'rgba(0,0,0,0.35)',
  blackSecondary: '#5a5a5a',
  blackThird: '#2a2444',
  textSecondary: '#393060',
  gray: '#8d8d8d',
  gray400: '#cccccc66',
  grayMain: '#CCCCCC',
  graySecond: '#9b9b9b',
  grayThird: '#96979a',
  grayFourth: '#f7f7f7',
  grayFifth: '#eaeaea',
  graySixth: '#eaeaea',
  graySeventh: '#979797',
  grayEighth: '#f9f9f9',
  grayNinth: '#707070',
  grayTenth: '#a9a9a9',
  grayEleventh: '#f4f4f4',
  grayTwelfth: '#aeaeae',
  grayThirteenth: '#e4e4e4',
  grayFourteenth: '#f6f6f6',
  periwinkleSecondary: '#6c62f5',
  periwinkleThird: '#aca6f5',
  periwinkleFourth: '#e6e5fe',
  red: '#d63031',
  redSecond: '#d0021b',
  redThird: '#ffdfe3',
  green: '#4abc4e',
  greenSecondary: '#edffed',
  greenThird: '#75c31e',
  greenFourth: '#5eb661',
  cream: '#fff0cf',
  link: '#4a90e2',
  blackMain: '#4a4a4a',
  blackdrop: '#4a4a4a59',
  blueLight: '#41376b',
  blueNight: '#5c558e',
  blueNightTwo: '#68648b',
  blueNightThird: '#4f4c75',
  transparent: '#00000000',
  yellow: '#f2f307',
  yellowSecondary: '#f0a323',
  yellowDark: '#f5a623',
  brownNight: '#5e5b78',
  dark: '#2a2444',
  greyblue: '#8192a7',
  slateGrey: '#5e5c78',
  greyishBrown: '#4a4a4a',
  appleGreen: '#76c520',
  lightGreyBlue: '#a1b1c2',
  periwinkle: '#887fff',
  veryLightPink: '#d5d5d5',
  black20: '#887fff33',
  brownGrey: '#838383',
  paleGrey: '#f6f5f8',
  lightPeriwinkle: '#ccc8ff',
  brownishGrey: '#626262',
  periwinkle20: '#887fff33',
}