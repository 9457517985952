import { createMuiTheme } from '@material-ui/core/styles';
import {placeholder} from "./pseudo";
import fonts from "./fonts";
const theme = createMuiTheme();

// Global Colors
const AxlColors = {
  dark: '#2a2444',
  greyblue: '#8192a7',
  slateGrey: '#5e5c78',
  greyishBrown: '#4a4a4a',
  appleGreen: '#76c520',
  lightGreyBlue: '#a1b1c2',
  periwinkle: '#887fff',
  veryLightPink: '#d5d5d5',
  black20: '#887fff33',
  brownGrey: '#838383',
  paleGrey: '#f6f5f8',
  lightPeriwinkle: '#ccc8ff',
  brownishGrey: '#626262',
  periwinkle20: '#887fff33',
}

// Colors
export const colors = {
  main: '#887fff',
  white: '#FFFFFF',
  whiteTwo: '#fafafa',
  blueEyes: "#f4f3ff",
  black: '#000000',
  black35: 'rgba(0,0,0,0.35)',
  blackSecondary: '#5a5a5a',
  blackThird: '#2a2444',
  textSecondary: '#393060',
  gray: '#8d8d8d',
  gray400: '#cccccc66',
  grayMain: '#CCCCCC',
  graySecond: '#9b9b9b',
  grayThird: '#96979a',
  grayFourth: '#f7f7f7',
  grayFifth: '#eaeaea',
  graySixth: '#eaeaea',
  graySeventh: '#979797',
  grayEighth: '#f9f9f9',
  grayNinth: '#707070',
  grayTenth: '#a9a9a9',
  grayEleventh: '#f4f4f4',
  grayTwelfth: '#aeaeae',
  grayThirteenth: '#e4e4e4',
  grayFourteenth: '#f6f6f6',
  grayFiveteenth: '#f4f5f4',
  periwinkle: '#887fff',
  periwinkleSecondary: '#6c62f5',
  periwinkleThird: '#aca6f5',
  periwinkleFourth: '#e6e5fe',
  red: '#d63031',
  redSecond: '#d0021b',
  redThird: '#ffdfe3',
  green: '#4abc4e',
  greenSecondary: '#edffed',
  greenThird: '#75c31e',
  greenFourth: '#5eb661',
  cream: '#fff0cf',
  link: '#4a90e2',
  blackMain: '#4a4a4a',
  blackdrop: '#4a4a4a59',
  blueLight: '#41376b',
  blueNight: '#5c558e',
  transparent: '#00000000',
  yellow: '#f2f307',
  yellowSecondary: '#f0a323',
  yellowDark: '#f5a623',
  brownNight: '#5e5b78',
  ...AxlColors
}

// Palette
const palette = {
  primary: colors,
  secondary: {
    main: '#2a2444',
    subsidiary: '#ccccc'
  },
  third: {
    main: '#6c62f5'
  },
  text: {
    primary: '#4f4c75',
    secondary: '#6c62f5',
    third: '#4a4a4a',
    select: '#737273'
  },
  background: {
    paper: "#FFF",
    list: "#f4f3ff"
  },
  color: AxlColors
};

//Typography
const typography = {
  fontFamily: 'AvenirNext',
  h1: {
    fontSize: 30,
    fontFamily: 'AvenirNext-DemiBold',
    marginBottom: theme.spacing(2),
    color: colors.black,
  },
  h2: {
    fontSize: 28,
    fontFamily: 'AvenirNext-DemiBold',
    marginBottom: theme.spacing(2),
    color: colors.black,
  },
  h3: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: 26,
    fontWeight: 600,
    color: colors.black,
    marginBottom: theme.spacing(2),
  },
  h4: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: 18,
    fontWeight: 600,
    color: colors.black,
  },
  h5: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: 16,
    fontWeight: 600,
    color: colors.black,
  },
  h6: {
    fontFamily: 'AvenirNext',
    fontSize: 14,
    fontWeight: 600,
    color: colors.black,
  },
  body1: {
    fontFamily: 'AvenirNext-Regular',
    fontSize: 16,
    fontWeight: 500,
    color: colors.black,
    lineHeight: 1.3,
  },
  body2: {
    fontFamily: 'AvenirNext-Regular',
    fontSize: 10,
    fontWeight: 500,
    color: colors.black,
  },
  subtitle1: {
    fontFamily: 'AvenirNext-Medium',
    fontSize: 20,
    fontWeight: 500,
    color: colors.black,
  },
  subtitle2: {
    lineHeight: '1.3em'
  },
  button: {
    fontFamily: 'AvenirNext-Medium',
    fontSize: 20,
    color: colors.white,
  }
};

// eslint-disable-next-line import/prefer-default-export
export const lightTheme = createMuiTheme({
  palette: palette,
  typography: typography,
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: colors.blackdrop
      },
      invisible: {}
    },
    MuiButton: {
      root: {
        borderRadius: 3
      },
      label: {
        fontFamily: 'AvenirNext',
        fontSize: 14,
        fontWeight: 600,
        textTransform: 'none',
        whiteSpace: 'nowrap'
      }
    },
    MuiCheckbox: {
      root: {
        color: colors.gray,
        '&$checked': {
          color: colors.periwinkle,
        }
      },
      colorPrimary: {
        color: colors.gray,
        '&$checked': {
          color: colors.periwinkle,
        }
      },
      colorSecondary: {
        color: colors.grayMain,
        '&$checked': {
          color: colors.grayFiveteenth,
        }
      },
    },
    MuiRadio: {
      root: {
        color: colors.gray,
        '&$checked': {
          color: colors.periwinkle,
        }
      },
      colorPrimary: {
        color: colors.gray,
        '&$checked': {
          color: colors.periwinkle,
        }
      },
      colorSecondary: {
        color: colors.grayMain,
        '&$checked': {
          color: colors.grayFiveteenth,
        }
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: 3,
        border: `1px solid ${colors.grayMain}`,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      input: {
        ...placeholder({
          fontFamily: 'AvenirNext-Medium',
          fontSize: 10,
          fontWeight: 500,
          color: colors.blackSecondary,
          opacity: 1,
        })
      },
      inputMultiline: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        ...placeholder({
          fontFamily: 'AvenirNext-Medium',
          fontSize: 10,
          fontWeight: 500,
          color: colors.blackSecondary,
          opacity: 1,
        })
      },
      colorSecondary: {
        backgroundColor: colors.whiteTwo
      }
    },
    MuiChip: {
      root: {
        height: 'auto',
        width: '100%',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(1),
      },
      label: {
        flex: 1,
        color: colors.black,
        fontSize: 16,
        fontFamily: fonts.Regular,
      },
      outlinedPrimary: {}
    }
  },
});
