import { selector } from "recoil";
import { stateAgreement } from "./state";

export const setAgreements = selector({
  key: 'selector/set/agreements',
  get: async ({ get }) => get(stateAgreement),
  set: ({ get, set }, data) => {
    const agreement = get(stateAgreement);
    const templates = agreement?.templates?.map(template => {
      if(template.id === data.id) {
        return {
          ...template,
          ...data,
        }
      }
      return template
    });
    set(stateAgreement, ({...agreement, templates}));
  }
});