import { create } from 'apisauce';
import * as axios from 'axios';
import {buildMobileDialogMessage, MOBILE_DIALOG_TYPE} from "../utils/mobileCommunication";

axios.defaults.withCredentials = true;

const driverAppApi = create({
  baseURL: `${process.env.REACT_APP_DRIVER_APP_API}`,
  withCredentials: true
});

driverAppApi.addResponseTransform(response => {
  if (response.status === 401 || response.status === 403) {
    if (window.TSAMessageChannel) {
      const json = JSON.stringify(buildMobileDialogMessage(
        MOBILE_DIALOG_TYPE.ERROR, "ERROR!",
        "Failed to authenticate! Your token has expired or you don't have permission to view this page.",
        "/back", "Got it"
      ));
      window.TSAMessageChannel.postMessage(json);
    } else {
      alert("Authentication Error!")
    }

    if (!response.headers['no-redirect']) {
      // window.location.href = `${process.env.REACT_APP_OAUTH_URL}/login?next=${encodeURI(window.location.href)}`;
    }
  }

  if (response.status == null || response.problem === "NETWORK_ERROR") {
    if (window.TSAMessageChannel) {
      const json = JSON.stringify(buildMobileDialogMessage(
        MOBILE_DIALOG_TYPE.ERROR, "NETWORK ERROR!",
        "Please check your internet connection.",
        "/back", "Got it"
      ));
      window.TSAMessageChannel.postMessage(json);
    }
  }
});

export default driverAppApi;