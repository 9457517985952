import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'

function AxlDialog({title, content, actions, handleOk, isOpen}) {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} classes={{paper: classes.rootDialogPaper}} maxWidth={'sm'}>
      <DialogTitle classes={{root: classes.rootDialogTitle}}>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions classes={{root: classes.rootDialogActions}}>
        {actions ? actions : (<Button classes={{root: classes.btnOk, label: classes.btnLabel}} onClick={handleOk}>OK</Button>)}
      </DialogActions>
    </Dialog>
  )
}

export default AxlDialog