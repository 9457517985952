import React, {useEffect} from 'react';
import {date2str, exportToCsv} from '../../../utils/sampleInputFile';

export default function AdvancedSampleInput() {
  const dropoff_earliest_datetime = date2str(new Date(Date.now() + 24*60*60*1000), 'MM/dd/yyyy hh:mm:00');
  const dropoff_latest_datetime   = date2str(new Date(Date.now() + 26*60*60*1000), 'MM/dd/yyyy hh:mm:00');

  useEffect(() => {
    exportToCsv('advanced-sample.csv', [
      ['pickup_street','pickup_street2','pickup_city','pickup_state','pickup_zipcode','order_id','dropoff_street','dropoff_street2','dropoff_city','dropoff_state','dropoff_zipcode','dropoff_earliest_datetime','dropoff_latest_datetime','dropoff_buffer','customer_name','customer_email','customer_phone_number','pickup_notes','dropoff_notes','delivery_items','signature_required','delivery_proof_photo_required','sms_enabled', 'dimension_unit', 'height', 'width', 'length', 'weight_unit', 'weight', 'value', 'currency' ,'dropoff_what3words'],
      ['2320 Warring Street','Hall B','Berkeley','CA','94704','BARCODE-123','1790 Jackson Street','Apartment 1',' San Francisco','CA','94109',dropoff_earliest_datetime ,dropoff_latest_datetime ,'300','Pamela Dummy','pamela@dummy.com','3108170029','Turn off the light before leaving the freezer','Do not ring the bell','Premium Box','1','0','1','in','10','15','10','lb','1','100.1','USD',''],
      ['2320 Warring Street','Hall B','Berkeley','CA','94704','BARCODE-124','1 Federal Street','Floor 2',' San Francisco','CA','94107',dropoff_earliest_datetime ,dropoff_latest_datetime ,'180','Lorraine Dummy','lorraine@dummy.com','3108170029','Ask the warehouse staff for instructions','Beware of dogs','Regular Box','0','1','1','in','10','15','10','lb','1','78.15','USD','///cable.fumes.watch'],
      ['2320 Warring Street','Hall B','Berkeley','CA','94704','BARCODE-125','1008 De Haro Street','Penthouse',' San Francisco','CA','94107',dropoff_earliest_datetime ,dropoff_latest_datetime ,'180','Loretta Dummy','loretta@dummy.com','3108170029','Do not park in the backyard','Leave it by the mailboxes','Premium Box','1','1','0','in','10','15','10','lb','1','45.01','USD','///weds.fingernails.also'],
      ['2320 Warring Street','Hall B','Berkeley','CA','94704','BARCODE-126','730 Florida Street','Basement 101',' San Francisco','CA','94110',dropoff_earliest_datetime ,dropoff_latest_datetime ,'360','Virginia Dummy','virginia@dummy.com','3108170029','Check in and check out the reception desk','Hide it in the bush ','Premium Box','0','0','0','in','10','15','10','lb','1','100.1','USD',''],
      ['2320 Warring Street','Hall B','Berkeley','CA','94704','BARCODE-127','1218 Treat Avenue','Detached Unit C',' San Francisco','CA','94110',dropoff_earliest_datetime ,dropoff_latest_datetime ,'180','Rashad Dummy','rashad@dummy.com','3108170029','Turn off the light before leaving the freezer','Place on the patio table','Free Box','1','1','1','in','10','15','10','lb','1','100.1','USD',''],
      ['2320 Warring Street','Hall B','Berkeley','CA','94704','BARCODE-128','30 Jersey Street','Room 105',' San Francisco','CA','94114',dropoff_earliest_datetime ,dropoff_latest_datetime ,'180','Eva Dummy','eva@dummy.com','3108170029','Ask the warehouse staff for instructions','My house is hard to find, call me when you arrive','Regular Box','1','1','1','in','10','15','10','lb','1','100.1','USD',''],
      ['2320 Warring Street','Hall B','Berkeley','CA','94704','BARCODE-129','1066 Guerrero Street','Ceiling',' San Francisco','CA','94110',dropoff_earliest_datetime ,dropoff_latest_datetime ,'180','David Dummy','david@dummy.com','3108170029','Do not park in the backyard','Baby inside, leave the box quietly','Ultimate Box','1','1','1','in','10','15','10','lb','1','55','USD','///edges.boring.really']
    ]);
  }, []);

  return <div>Downloading...</div>;
};