import React, {useEffect, useState} from 'react';
import {withStyles, Box, FormControl, Button, FormControlLabel, FormGroup, RadioGroup, Radio, Checkbox} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";

// internal
import styles from "./styles";

function Quiz(props) {
  const {classes, quizList, changeAnswer, answered, showAnswer, setAutoShowAns, disabled} = props;

  useEffect(() => {
    if (quizList && quizList.length) {
      let autoShowAns = false
      const typeArray = _.flatMap(quizList, (quiz) => {
        return _.flatMap(quiz.questions, (qs) => qs.type);
      });
      const typeSet = _.uniq(typeArray);

      if (typeSet.length === 1 && typeSet.includes('button')) {
        autoShowAns = true;
      }
      setAutoShowAns(autoShowAns);
    }
  }, [quizList])

  if (!quizList || !quizList.length) {
    return null;
  }

  const handleChange = (e, qid) => {
    const {value, checked, type} = e.target;
    changeAnswer(value, checked, type, qid);
  }

  return (
    <Box className={classes.container}>
      {quizList.map(quiz => (
        <Box key={quiz.id}>
          <Box component="h4" m={0} my={1} className={classes.quiz}>{quiz.title}</Box>
          <Box component="small">{quiz.text}</Box>
          {quiz.questions && quiz.questions.map(question => {
            const questionAnswered = answered[question.id] || [];
            const correctAnswers = quiz.answers[question.id] || [];
            const corrected = correctAnswers.every(a => questionAnswered.includes(a)) && correctAnswers.length === questionAnswered.length;

            let Group = FormGroup;
            let Control = Checkbox;
            if (question.type === 'radio') {
              Group = RadioGroup;
              Control = Radio;
            } else if (question.type === 'checkbox') {
              Group = FormGroup;
              Control = Checkbox;
            }

            let formContent = (
              <FormControl disabled={disabled} fullWidth>
                <Group onChange={(e) => handleChange(e, question.id)}>
                  {question.choices.map(choice => {
                    const checked = questionAnswered.includes(choice.value);
                    const checkedText = choice.checked || "OK";

                    let labelAnswer = <span><em>{choice.title || ''}</em> {choice.text}</span>;
                    if (question.type === 'button') {
                      labelAnswer = (
                        <Button
                          variant="contained" color="primary"
                          disabled={checked} disableElevation
                          className={classes.button}
                          onClick={() => changeAnswer(choice.value, true, question.type, question.id)}
                        >
                          {!checked ? choice.text : checkedText}
                        </Button>
                      );
                    }

                    return (
                      <FormControlLabel
                        key={choice.value}
                        value={choice.value}
                        control={<Control color="primary" checked={checked} style={{display: question.type === 'button' ? 'none' : 'block'}} />}
                        label={labelAnswer}
                        className={clsx({
                          [classes.answer]: question.type !== 'button',
                          [classes.answerButton]: question.type === 'button',
                          [classes.checked]: checked,
                          [classes.correctAnswer]: showAnswer && corrected && correctAnswers.includes(choice.value),
                        })}
                      />
                    )
                  })}
                </Group>
              </FormControl>
            );

            return (
              <Box key={question.id} my={1}>
                <Box component="h5" m={0} my={1}
                     className={clsx({
                       [classes.wrong]: showAnswer && !corrected,
                       [classes.correct]: showAnswer && corrected,
                     })}
                >
                  {question.title}
                </Box>
                <Box component="small">{question.text}</Box>
                <Box mt={1} mb={2}>
                  {formContent}
                </Box>
              </Box>
            )
          })}
        </Box>
      ))}
    </Box>
  )
}

export default withStyles(styles)(Quiz);