import colors from "../../themes/colors";

const styles = theme => ({
  container: {
    fontFamily: 'AvenirNext-Regular',
  },
  quiz: {
    textDecoration: 'underline',
  },
  answer: {
    border: '2px solid #eee',
    padding: theme.spacing(1, 0.5, 1, 0),
    margin: theme.spacing(0.5, 0),
  },
  answerButton: {
    margin: theme.spacing(0.5, 0),
    textAlign: 'center',
    display: 'block',
  },
  checked: {
    borderColor: colors.periwinkle,
  },
  correctAnswer: {
    borderColor: colors.green,
  },
  wrong: {
    color: colors.redSecond,
  },
  correct: {
    color: colors.green,
  },
  buttonWrapper: {
    textAlign: 'center',
  },
  button: {
    borderWidth: 2,
    borderRadius: 20,
    borderStyle: 'solid',
    '&:hover': {
      borderWidth: 2,
    },
  }
})

export default styles;