import React from "react";
import {Box, Grid} from "@material-ui/core";

export default function HomeScreen(props) {
  return (
    <Box m={2} style={{height: 'calc(100vh - 32px)'}}>
      <Grid container justify="center" alignItems="center" direction="column" style={{height: '100%'}}>
        <Grid item>
          <Grid container alignItems="center" style={{color: '#3b3b3b', fontFamily: "AvenirNext-Medium", fontSize: 20}}>
            <img src="../assets/svg/logo.svg" alt="Jitsu logo" width="100" height="100" />
          </Grid>
        </Grid>
        <Grid item style={{flex: 1}} alignContent="center">
          <Box mt={10} component="h1">Welcome to Jitsu</Box>
        </Grid>
      </Grid>
    </Box>
  )
}