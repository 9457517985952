const styles = theme => ({
  container: {
    height: '100%',
    padding: 5,
  },
  actions: {
    textAlign: "center",
    marginTop: 5
  },
  agreeButton: {
    minWidth: 150,
    backgroundImage: "linear-gradient(to right, rgb(136,127,255), rgb(106,244,255))",
    borderRadius: 30,
    '&:disabled': {
      backgroundImage: "unset",
    }
  },
  error: {
    color: '#f00',
    fontStyle: 'italic',
    fontFamily: "AvenirNext-DemiBold",
    fontWeight: 500,
  },
  typoCongratulations: {
    fontFamily: "AvenirNext",
    fontWeight: 400,
    color: 'rgb(98, 98, 98)',
    margin: '8px 0px',
  }
});

export default styles;
