const styles = theme => ({
  wrapper: {
    height: '100%',
    fontFamily: "AvenirNext-Regular",
  },
  body: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    paddingBottom: 48,
  },
  timer: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: 16,
    color: '#6c62f5',
    lineHeight: '1.3em',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  note: {
    textAlign: 'center',
    color: "#6c62f5",
    fontSize: '0.8rem',
    fontStyle: 'italic',
  }
})

export default styles;