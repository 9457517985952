import React, { Suspense } from "react";
import {ThemeProvider} from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// internal
import './App.css';
import {lightTheme} from "./themes";
import HomeScreen from "./screens";
import Course from "./screens/Course";
import AdvancedSampleInput from "./screens/SampleInput/Advanced";
import SimpleSampleInput from "./screens/SampleInput/Simple";
import Agreement from "./screens/Agreement";
import Documentation from "./screens/Document";
import AgreementList from "./screens/AgreementList";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <RecoilRoot>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/simple-sample-input" component={SimpleSampleInput} />
              <Route exact path="/advanced-sample-input" component={AdvancedSampleInput} />
              <Route exact path="/course/:id" component={Course} />
              <Route exact path="/agreement/:id" component={Agreement} />
              <Route exact path="/document/:id" render={props => <Documentation {...props} isFinal={true} />} />
              <Route exact path="/agreements" component={AgreementList} />
              <Route path="/" component={HomeScreen} />
            </Switch>
          </Suspense>
        </Router>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
