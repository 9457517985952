import { makeStyles } from "@material-ui/core";
import colors from "../../themes/colors";

export const useStyles = makeStyles((theme) => ({
  rootDialogPaper: {
    borderRadius: 13,
  },
  rootDialogTitle: {
    padding: '0px 24px',

    "& > :first-child": {
      paddingTop: 16,
      fontSize: 18,
      textAlign: 'center',
    }
  },
  rootDialogActions: {
    justifyContent: 'center',
    paddingTop: 16,
  },
  btnOk: {
    minWidth: 150,
    backgroundImage: "linear-gradient(to right, rgb(136,127,255), rgb(106,244,255))",
    borderRadius: 30,
    color: colors.white,
  },
  btnLabel: {
    fontSize: 16,
  }
}))