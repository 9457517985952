import _ from 'lodash';

export const filterTemplate = (data, includeCats, excludeCats) => {
  if(!data) return [];
  const filteredExcludeCategories = _.filter(excludeCats, _.size);
  const filterByExcludes = data?.filter(at => {
    if (filteredExcludeCategories?.length === 0 || !filteredExcludeCategories.includes(at.category)) return true;
    return false;
  }) || [];

  const filteredIncludeCategories = _.filter(includeCats, _.size);
  return filterByExcludes?.filter(at => {
    if (filteredIncludeCategories?.length === 0 || filteredIncludeCategories.includes(at.category)) return true;
    return false;
  })
}