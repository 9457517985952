import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';
import { Box, Button, LinearProgress } from '@material-ui/core';
import _ from 'lodash';

import Detail from './detail';
import { stateAgreement } from '../../atoms/agreements/state';
import { getAgreements } from '../../stores/repositories';
import { filterTemplate } from '../../utils/filterTemplate';
import { URL_PARAMS } from '../../constants/urlParams/urlParams';

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

function AgreementList(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get(URL_PARAMS.TOKEN);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [documentSigned, setDocumentSigned] = useState({});
  const [agreements, setAgreements] = useRecoilState(stateAgreement);
  const [includeCats, setIncludeCats] = useState(urlParams.get(URL_PARAMS.INCLUDES)?.split(",") || []);
  const [excludeCats, setExcludeCats] = useState(urlParams.get(URL_PARAMS.EXCLUDES)?.split(",") || []);

  useEffect(() => {
    if(!agreements) {
      setLoading(true);
      getAgreements(token).then(res => {
        if(!res.ok) {
          setError(res.data ? res.data.message : "Error while sent request");
          return;
        }
        setAgreements(res.data);
        setError('');
      }).finally(() => setLoading(false))
    }
  }, [token]);

  if (error) {
    return (
      <Box textAlign={'center'} p={2} color='red'>{error || 'Fail to load agreement!'}</Box>
    )
  }

  if(documentSigned?.isOpen) {
    return (
      <Box display={'flex'} flexDirection={'column'} height={'100vh'}>
        <Box display={'flex'}>
          <Button onClick={() => setDocumentSigned({contractUrl: null, isOpen: false})} variant='contained' color='primary' fullWidth style={{borderRadius: 0}}>Back</Button>
        </Box>
        <Box flex={1} mt={isChrome ? 0 : -4.375}>
          <iframe width="100%" height="98%" src={documentSigned.contractUrl} style={{border: 'none'}}>...Loading</iframe>
        </Box>
      </Box>
    )
  }

  return (
    <Box pt={3}>
      {loading ? <LinearProgress /> : (
        filterTemplate(agreements?.templates, includeCats, excludeCats)?.map(template => <Detail template={template} groupIds={agreements.template_ids} token={token} key={template?.id} includeCats={includeCats} setDocumentSigned={setDocumentSigned} excludeCats={excludeCats}/>)
      )}
    </Box>
  )
}

export default AgreementList