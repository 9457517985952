import React, {useEffect, useState} from 'react';
import {withStyles, Box, Grid, Button, CircularProgress} from "@material-ui/core";
import _ from "lodash";

import styles from "./styles";
import TrainingStep from "../TrainingStep";
import * as repo from "../../stores/repositories";

function Course(props) {
  const {classes, match} = props;
  const {params} = match;
  const [submitting, setSubmitting] = useState(false);
  const [loadingCourse, setLoadingCourse] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [timeoutReached, setTimeoutReached] = useState(false);
  const [bottomReached, setBottomReached] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [autoShowAns, setAutoShowAns] = useState(false);
  const [step, setStep] = useState(0);
  const [totalStep, setTotalStep] = useState(0);
  const [course, setCourse] = useState({});
  const [countdown, setCountdown] = useState(0);
  const [answered, setAnswered] = useState({});
  const [answerCorrect, setAnswerCorrect] = useState(false);
  const [error, setError] = useState("");
  const training = course && course.trainings ? course.trainings[step] : null;
  const progressWidth = (Math.min(100/totalStep * (step + 1), 100)) + '%';
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('tk');
  const preview = urlParams.get("preview");
  const driverCourseId = params.id;
  let timer = null;

  useEffect(() => {
    if (driverCourseId && !course.id) {
      setLoadingCourse(true);
      setError('');
      repo.getTraining(driverCourseId, token, {preview}).then(res => {
        if (res.ok) {
          setCourse(res.data);
          const totalStep = res.data.trainings.length - 1;
          let lastIncompleteStep = -1;
          if (!res.data.description) lastIncompleteStep = 0;
            for (let i = 0; i < res.data.trainings.length; i++) {
            const tr = res.data.trainings[i];
            if (tr.data.completed) {
              lastIncompleteStep = i + 1;
            }
          }
          if (preview === "true") {
            setStep(0);
          } else {
            setStep(Math.min(lastIncompleteStep, totalStep));
          }
          setTotalStep(totalStep);
        } else {
          setError(res.data ? res.data.message : "Error while sent request");
        }
        setLoadingCourse(false);
      })
    }
  }, [])

  useEffect(() => {
    if (timeoutReached && bottomReached) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [timeoutReached, bottomReached])

  useEffect(() => {
    if (training && !training.data.completed) {
      if (training.timeout) {
        setTimeoutReached(false);
        setCountdown(training.timeout);
      } else {
        setCountdown(0);
        setTimeoutReached(true);
      }

      if (training.content && training.content.length > 500) {
        setBottomReached(false);
      } else {
        setBottomReached(true);
      }
      if (!_.isEmpty(training.data.answers)) {
        setAnswered({...training.data.answers, ...answered});
      }
      setShowAnswer(false);
      setAnswerCorrect(false);
    } else {
      setCountdown(0);
      setTimeoutReached(true);
      setBottomReached(true);
      setShowAnswer(training && training.data.completed);
      setAnswerCorrect(training && training.data.completed);
      if (training && !_.isEmpty(training.data.answers)) {
        setAnswered({...training.data.answers, ...answered});
      }
    }
    if (totalStep && step === totalStep) {
      saveData({completed: true, answers: {}}, false);
    }
  }, [step, course.trainings, totalStep])

  useEffect(() => {
    if (!countdown) {
      clearInterval(timer);
      return;
    }

    timer = setInterval(() => {
      setCountdown(countdown - 1);
      if (countdown === 1) {
        setTimeoutReached(true);
        document.getElementById("training-body").click();
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown])

  useEffect(() => {
    if (autoShowAns) {
      setAnswerCorrect(isAnswerCorrect());
    }
  }, [answered, step])

  const onBack = () => {
    setError('');
    setStep(Math.max(step - 1, -1));
  }

  const onNext = () => {
    setError('');
    const currentAnswered = {};
    if (training && training.quiz_list) {
      training.quiz_list.forEach(quiz => {
        quiz.questions.forEach(q => {
          currentAnswered[q.id] = answered[q.id];
        })
      });
    }

    if (autoShowAns) {
      saveData({completed: answerCorrect, answers: currentAnswered}, answerCorrect);
      return;
    }

    if (training && training.quiz_ids && training.quiz_ids.length > 0 && !answerCorrect) {
      const corrected = isAnswerCorrect();
      setShowAnswer(true);
      saveData({completed: corrected, answers: currentAnswered}, false).then(res => {
        if (res.ok) {
          setAnswerCorrect(corrected);
        }
      });
      return;
    }

    if (step > -1 && training && training.data) {
      if (!training.data.completed) {
        if (!answerCorrect) {
          saveData({completed: true, answers: currentAnswered});
        }
        completeStep();
        if (answerCorrect) {
          setStep(Math.min(step + 1, totalStep));
        }
      } else {
        setStep(Math.min(step + 1, totalStep));
      }
    } else {
      if (step < 0) {
        // start course
        setSubmitting(true);
        repo.startTraining(driverCourseId, token).then(res => {
          if (res.ok) {
            setStep(Math.min(step + 1, totalStep + 1));
          } else {
            setError(res.data ? res.data.message : 'Error while sent request');
          }
          setSubmitting(false);
        })
      } else {
        setStep(Math.min(step + 1, totalStep));
      }
    }
  }

  const completeStep = () => {
    // update step to complete
    course.trainings = course.trainings.map(t => {
      if (training && t.id === training.id) {
        t.data.completed = true;
      }
      return t;
    });
    setCourse(course);
  }

  const saveData = (data, autoNext = true) => {
    setSubmitting(true);
    return repo.updateTraining(driverCourseId, training.id, data, token).then(res => {
      if (res.ok) {
        if (autoNext) {
          completeStep();
          setStep(Math.min(step + 1, totalStep + 1));
        }
      } else {
        setError(res.data ? res.data.message : 'Error while sent request');
      }
      setSubmitting(false);
      return res;
    })
  }

  const scrollTracking = (e) => {
    // add a little delta to avoid bug on some screen
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop < e.currentTarget.clientHeight + 10) {
      setBottomReached(true);
    }
  }

  const changeAnswer = (value, checked, type, qid) => {
    setError('');
    if (showAnswer) {
      setShowAnswer(false);
    }

    if (autoShowAns) {
      setShowAnswer(true);
      const currentAnswered = {};
      if (training && training.quiz_list) {
        training.quiz_list.forEach(quiz => {
          quiz.questions.forEach(q => {
            currentAnswered[q.id] = answered[q.id];
          })
        });
      }
    }

    const newAnswer = Object.assign({}, answered);
    if (checked) {
      if (!newAnswer[qid] || type === 'radio') {
        newAnswer[qid] = [value];
      } else if (!newAnswer[qid].includes(value)) {
        newAnswer[qid].push(value);
      }
    } else {
      newAnswer[qid] = newAnswer[qid].filter(vl => vl !== value);
    }

    setAnswered(newAnswer);
  }

  const isAnswerCorrect = () => {
    let allCorrected = true;
    if (!training || !training.quiz_list) {
      return true;
    }

    training.quiz_list.forEach(quiz => {
      quiz.questions.forEach(question => {
        const answer = answered[question.id] || [];
        const correctAnswer = quiz.answers[question.id];

        if (!(answer.length === correctAnswer.length && correctAnswer.every(a => answer.includes(a)))) {
          allCorrected = false;
        }
      })
    })
    return allCorrected;
  }

  if (!driverCourseId) {
    return (
      <Box p={3} align="center">Training course ID is required.</Box>
    )
  }

  if (loadingCourse) {
    return (
      <Grid container style={{height: '100vh'}} justify="center" alignItems="center">
        <CircularProgress color="primary" />
      </Grid>
    )
  }

  if (!course.id) {
    return (
      <Box p={3} align="center">{error}</Box>
    )
  }

  if (!course.trainings || !totalStep) {
    return (
      <Box p={3} align="center">This course has no training in it</Box>
    )
  }

  let trainingData = step < 0 ? {title: course.title, content: course.description} : training;
  // let nextButtonText = (
  //   <>
  //     <span>Continue</span>
  //     <ArrowForwardIcon color="inherit" />
  //   </>
  // );

  let nextButtonText = (
    <span>Next</span>
  );

  if (step < 0) {
    nextButtonText = <span>Get Started</span>
  }

  if (training && training.quiz_ids && training.quiz_ids.length > 0) {
    if (!answerCorrect && !autoShowAns) {
      nextButtonText = <span>Check Answer</span>
    }
  }

  if (trainingData && trainingData.next) {
    nextButtonText = trainingData.next;
  }

  return (
    <Box m={3}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={6}>
          <Grid container alignItems="center" className={classes.logo}>
            <img src="../assets/svg/logo.svg" alt="Jitsu logo" width="100" height="100" />
          </Grid>
        </Grid>
        {totalStep && step > -1 && step < totalStep && (
          <Grid item xs={6}>
            <Grid container spacing={1} alignItems="center" justify="space-between">
              <Grid item style={{flex: 1}}>
                <Box className="process-bar">
                  <Box className="process-completed" style={{width: progressWidth}} />
                </Box>
              </Grid>
              <Grid item>
                <Box className={classes.stepText}>{step + 1}/{totalStep}</Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box pt={3} pb={2} className={classes.trainingBody}>
        <TrainingStep
          id="training-body"
          step={step}
          totalStep={totalStep}
          training={trainingData}
          countdown={countdown}
          onScroll={scrollTracking}
          changeAnswer={changeAnswer}
          answered={answered}
          showAnswer={showAnswer}
          answerCorrect={answerCorrect}
          setAnswerCorrect={setAnswerCorrect}
          setAutoShowAns={setAutoShowAns}
        />
      </Box>
      <Box px={2} py={1} className={classes.stepAction}>
        {error && (
          <Box p={0.5} align="center" className={classes.error}>{error}</Box>
        )}
        <Grid container justify="space-between">
          <Grid item>
            {step < totalStep && (
              <>
                {step >= 0 && (
                  <Button onClick={onBack} disabled={submitting} variant="outlined" color="secondary" disableElevation className={classes.actionButton}>
                    {trainingData && trainingData.prev ? trainingData.prev : "Previous"}
                  </Button>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              {submitting && (
                <Box px={1}>
                  <CircularProgress color="primary" size={30} />
                </Box>
              )}
              {step < totalStep && (
                <Button onClick={onNext} disabled={!canNext || submitting || (autoShowAns && !answerCorrect)} variant="contained" color="primary" disableElevation className={classes.actionButton}>
                  {nextButtonText}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default withStyles(styles)(Course);