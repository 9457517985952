export const buildMobileDialogMessage = (type, title, description, redirect_to, ok_text) => (
  {type, title, description, redirect_to, ok_text}
);

export const buildButtonActon = (action, url, title) => (
  {action, url, title}
);

export const MOBILE_DIALOG_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
}